import * as Analytics from '@iheartradio/web.analytics';

import { useAnalytics } from './create-analytics';

export type ItemSelectedType = {
  pageName: string;
  section: string;
  context: string;
  assets: Analytics.Analytics.Asset;
  sectionPosition: number;
  itemPosition: number;
};

export function useItemSelected() {
  const analytics = useAnalytics();

  const onItemSelected = ({
    pageName,
    section,
    context,
    assets,
    sectionPosition,
    itemPosition,
  }: ItemSelectedType) => {
    analytics.track({
      type: Analytics.eventType.enum.ItemSelected,
      data: {
        station: assets,
        item: {
          asset: {
            id:
              'subid' in assets.asset && assets.asset.subid
                ? assets.asset.subid.toString()
                : assets.asset.id.split('|')[1],
            name:
              'subname' in assets.asset && assets.asset.subname
                ? assets.asset.subname
                : assets.asset.name,
          },
        },
        event: {
          location: `${pageName}|${section}|${context}`,
        },
        view: {
          sectionPosition,
          itemPosition,
        },
      },
    });
  };

  return { onItemSelected };
}
